import React from "react"
import { graphql } from "gatsby"
import { motion } from "framer-motion"
import { Link } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import Layout from "../../components/layout"
import Seo from "../../components/seo"

import ContactSection from "../../components/contactSection"
import PageIntro2 from "../../components/PageIntro2";
import { GatsbyImage } from "gatsby-plugin-image"
import SectionIntroVersion2 from "../../components/sectionIntroVersion2";

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }

function Service({ heading, heading2, category, text, images, id, cta, imageRight, keywords, htmlId }){
  
      let featuredImage
  
      images.forEach( item => {
              if(item.node.name === id) {
                  featuredImage = item.node.childImageSharp.gatsbyImageData
              }
          }
      )
  
      return (
      <Link to="/contact" className="hover:cursor-pointer">
        <div className="bg-white">
          <div 
            className="flex flex-wrap items-center justify-between container mx-auto"
            style={{
                flexDirection: imageRight ? "row-reverse" : "row"
              }}>

              <div className="flex flex-col w-full sm:pt-4 lg:w-1/2 md:py-12 pt-12 lg:p-16 xl:p-24">            
                  <h2 className="text-xs mb-3 uppercase tracking-wider font-bold text-neutral-500">
                      { category }
                  </h2>
                  <div className="text-4xl mb-4 font-bold font-display">
                      <h3>
                        { heading }
                      </h3>
                      <h3>
                        { heading2 }
                      </h3>
                  </div>

                  <p className="text-base text-neutral-700 mb-6">
                      { text }
                  </p>
                  <div className="flex space-between">
                      {/* <p className="text-lg text-cyan-600 mb-6 hover:underline mr-8">
                          Learn more<FontAwesomeIcon icon={ faChevronRight } className="ml-2 h-4"/>
                      </p> */}
                      <Link to="" className="text-lg text-cyan-600  mb-6 hover:underline">
                         { cta }<FontAwesomeIcon icon={ faChevronRight } className="ml-2 h-4"/>
                      </Link>
                  </div>
                  <div className="flex flex-wrap text-neutral-600">         
                        { keywords.map( item =>
                            <div className="flex align-center w-1/2 text-xs sm:text-md md:text-base pb-2"><FontAwesomeIcon icon={ faCircleCheck } className="text-neutral-600 fa-xl"/><div className="ml-2">{item}</div></div>
                        )}
                  </div>
              </div>

              <div className="lg:w-1/2">
                  <GatsbyImage image={ featuredImage } alt={`Image for ${category}`} />
              </div>

          </div>
        </div>
      </Link>
    )
  }
  
  
  
function ServicesDisplay({ data }) {
  
      const images = data.allFile.edges

      const development = [
        {
          category: "New store design and development",
          heading: "You dream it.",
          heading2: "We build it.",
          id: "1",
          text: "Join the over 1.7 million businesses that sell on Shopify. Smoothly, quickly, and easily.",
          extraText: "",
          keywords: [
              "Shopify Partners",
              "New Shopify store",
              "Responsive design",
              "Bespoke design",
              "Premium themes", 
              "Built-in CMS",
              "App integration",
              "E-commerce",
              "Currency Selector",
              "Language Selectors",
          ],
          link: "/services",
          cta: "Start selling",
          imageRight: true,
 
        },
        {
          category: "Migration",
          heading: "Switching to Shopify?",
          heading2: "We got you.",
          id: "24",
          text: "Moving to Shopify has never been easier—no matter what platform you are currently using.",
          extraText: "",
          keywords: [
              "WooCommerce",
              "Magento",
              "Wix",
              "Squarespace",
              "Amazon",
              "BigCommerce",
              "BigCartel",
              "WordPress",
          ],
          link: "/services",
          cta: "Switch to Shopify",
          imageRight: false,
        },
        {
          category: "Theme Customization",
          heading: "See your unique vision come to life.",
          id: "34",
          text: "Stand out and drive sales with bespoke, personalized features that your customers will love.",
          extraText: "",
          keywords: [
              "Redesigns",
              "Bug fixes",
              "Tailored digital experience",
              "Theme customization",
              "Custom features",
              "Custom CMS",
              "Custom Shopify themes",
              "Dynamic free-shipping bar",
              "Shopify Partners"
          ],
          link: "/services",
          cta: "Improve your website",
          imageRight: true,
        },
        {
          category: "App Integration",
          // heading: "Hello, automation.",
          heading2: "Hello, free time.",
          id: "43",
          text: "Give yourself more time to focus on your customers with the 6,000+ apps and solutions Shopify has to offer.",
          extraText: "",
          keywords: [
              "Facebook channel",
              "Google channel",
              "Point of Sale",
              "Oberlo",
              "Pinterest",
              "Klaviyo",
              "Mailchimp",
              "Order Printer",
              "Judge.me",
              "Loox",
              "Instafeed",
              "Free Shipping Bar",
              "Matrixify",
          ],
          link: "/services",
          cta: "Integrate your favorite apps",
          imageRight: false,
        }
      ];

      const optimization = [
        {
          category: "Conversion-Rate Optimization",
          heading: "Drive more sales through data.",
          id: "55",
          text: "Get to know who your customers are, how they behave, and what drives them, so you can sell better, smarter, more.",
          extraText: "",
          keywords: [
              "Google Analytics",
              "Market research",
              "Facebook Pixels",
              "Optimize by Google",
              "HotJar",
              "VWO",
              "Subscriptions",
              "Bulk selling",
          ],
          link: "/services",
          cta: "Optimize your store",
          imageRight: true,
        },
        {
          category: "Speed optimization",
          heading: "Your store just got faster.",
          id: "6",
          text: "Retain more visitors and reduce your website’s bounce rate by delivering faster page loading times.",
          extraText: "",
          keywords: [
              "Pagespeed Insights",
              "Faster loading time",
              "Lower visitor bounce rate",
              "Image optimization",
              "Lighthouse performance score",
          ],
          link: "/services",
          imageRight: false,
          cta: "Streamline your website"
        },
        {
          category: "Search Engine Optimization",
          heading: "Grow your organic traffic.",
          id: "7",
          text: "Increase your traffic organically and rank higher on search engines by shaping your website’s content to your potential customer.",
          extraText: "",
          keywords: [
              "Google Keywords",
              "SEO-friendly meta data",
              "SEO keywords",
              "Content relevance",
              "Backlinks", 
              "Link popularity"
          ],
          link: "/services",
          cta: "Optimize your store",
          imageRight: true,
        }
      ];

      const marketing = [
        {
          category: "Marketing",
          heading: "More reach.",
          heading2: "More sales.",
          id: "91",
          text: "Drive more customers to your store and engage a world-wide audience with thoughtful, data-driven campaigns.",
          extraText: "",
          keywords: [
            "Klaviyo",
            "mailChimp",
            "Copy writing",
            "Google Ads",
            "Pinterest Ads",
            "Instagram Ads",
            "Facebook Ads",
            "Email Marketing",
            "Click-through-rate optimization",
            "Social Media campaigns"
          ],
          link: "/services",
          cta: "Grow your reach",
          imageRight: true,
        },
        {
            category: "Branding",
            heading: "Shape your brand.",
            id: "82",
            text: "Inspire loyalty with a unique brand that resonates with your vision and customers.",
            extraText: "",
            keywords: [
                "Brand identity", 
                "Rebrands", 
                "Packaging", 
                "Logo design", 
                "Style guide"
            ],
            link: "/services",
            // cta: "Design your vision",
            cta: "Tell your story",
            imageRight: false,
        },
    ];
  
      return (
          <>
              {
                development.map( (service, idx) => 
                  <Service { ...service } images={ images } key={ idx } />
                  )
              }
              <SectionIntroVersion2 
                title="Optimization"
                headline="Sell smarter."
                headline2="Sell more."
                text="Engage your customers the right way with speed-optimized, data-driven, personalized experiences."
              />
            
              {
                optimization.map( (service, idx) => 
                <Service { ...service } images={ images } key={ idx } />
                  )
              }
              <SectionIntroVersion2 
                title="Branding & Marketing"
                headline="Hyper-scale your reach."
                text="Amplify your brand’s voice and drive more customers to your store by telling your story the right way."
              />
              {
                marketing.map( (service, idx) => 
                <Service { ...service } images={ images } key={ idx } />
                  )
              }

          </>
      )
  }

const ServicesPage = ({ data }) => {
  

  
    return (
  
    // <motion.div
    //   initial='initial'
    //   animate='animate'
    //   exit="exit"
    //   className=""
    //   >
      <Layout navBarType="white">
          <Seo title="Services" />

              <PageIntro2
                title="What we do"
                headline="Bring your vision to Shopify."
                text="Capture the attention of your customers and inspire sales with our Shopify solutions."
                height={ '80vh'}
              />  

              <ServicesDisplay data={ data }/>

              <ContactSection />
    
      </Layout>
    // </motion.div>
)}




export default ServicesPage



export const query = graphql`
query ServicesPageQuery {
    allFile(
        filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "service-page"}}
        ) {
        edges {
            node {
            childImageSharp {
                gatsbyImageData(placeholder: NONE)
            }
            name
            }
        }
    }
}
`