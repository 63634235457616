import * as React from "react"

const SectionIntroVersion2 = ({ title, headline, headline2, text}) => {
    
    return (
        <div className="py-24 md:pt-32 lg:pb-24 bg-white">
            <div className="flex flex-col items-center text-center container mx-auto">
                <p className="text-sm max-w-xl mb-6 uppercase tracking-wider font-bold text-neutral-500">{ title }</p>
                <h2 className="text-5xl lg:text-6xl mb-6 font-bold font-display">
                    <div>{ headline }</div>
                    <div className="block">{ headline2 }</div>
                </h2>
                <p className="text-base sm:w-2/3 lg:w-1/2">{ text }</p>
            </div>
        </div>
)}

export default SectionIntroVersion2