import * as React from "react"
import useWindowHeight from "./useWindowHeight"

function PageIntro({ title, headline, headline2, text, height, minHeight }) {

    const vHeight = useWindowHeight();

    return (
      <section className="w-full text-left sm:text-center flex flex-col bg-gradient-to-t from-white to-cyan-300 items-center justify-center" style={{ minHeight: 400, height: height ? height : vHeight }}>
      {/* <section className="w-full text-left sm:text-center flex flex-col bg-cyan-100 items-center justify-center border-b border-b-px border-neutral-600" style={{ height: height ? height : vHeight }}> */}
        <h2 className="text-6xl sm:text-6xl md:text-7xl lg:text-8xl 2xl:text-8xl mb-8 container">
            <div>{ headline }</div>
            <div className="pt-2 block">{ headline2 }</div>
        </h2>
        <p className="text-base lg:text-lg sm:w-2/3 lg:w-2/3 text-neutral-800 tracking-wide container">{ text }</p>
      </section> 
    )
  }




export default PageIntro